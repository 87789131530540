import React from "react";

const Footer = () => {
    function getYear() {
        return new Date().getFullYear();
    }
    return (
        <footer>
            <p>© {getYear()} Ian Zack</p>
        </footer>
    )
}

export default Footer;