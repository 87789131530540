import React from "react";
import Navbar from "../components/navbar";
import SayNoToTheDevil from "../components/SayNoToTheDevil";
import Odetta from "../components/Odetta";

const BooksPage = () => {
    return (
        <div>
            <Navbar />
            <Odetta />
            <hr className="books-seperator" />
            <SayNoToTheDevil />
        </div>
    )
}

export default BooksPage;