import "./css/main.css";
import "./css/animate.css";
import { Route, Routes } from 'react-router';
import Home from './pages/Home';
import { BrowserRouter } from 'react-router-dom';
import reveal from "./animationscript";
import BooksPage from "./pages/Books";
import { useEffect } from "react";
import Odetta from "./components/Odetta";
import SayNoToTheDevil from "./components/SayNoToTheDevil";
import { clarity } from "react-microsoft-clarity";

function App() {
  useEffect(() => {
    // Google Tag Manager
    const gtagScript = document.createElement("script");
    gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-Q6VGM6TKCL";
    gtagScript.async = true;
    document.body.appendChild(gtagScript);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-Q6VGM6TKCL');

    const handleScroll = () => {
      reveal();
    };

    clarity.init("lpigh33whj")

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener and the script
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.removeChild(gtagScript);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' Component={Home} />
        <Route path='/books' Component={BooksPage} />
        <Route path="/books/odetta" Component={Odetta}/>
        <Route path="/books/say-no-to-the-devil" Component={SayNoToTheDevil} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
