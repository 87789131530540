import React from "react";
import Navbar from "../components/navbar";
import Books from "../components/books";
import Front from "../components/front";
import InPress from "../components/in-press";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";
import News from "../components/news";
import Bio from "../components/bio";
import { HeadProvider as Head, Title } from "react-head";

const Home = () => {
    return (
        <div>
            <Head>
                <Title>Ian Zack | Author & Journalist</Title>
            </Head>
            <Navbar />
            <Front />
            <Books />
            <InPress />
            <Bio />
            <News />
            <ContactForm />
            <Footer />
        </div>

    )
}

export default Home;