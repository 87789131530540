import React from "react";
import cover from "../images/homebackground.gif";
const Front = () => {
    return (
        <section id="home">
            <img alt="home background" src={cover} />
            <div>
                <h1 className="text-focus-in">Ian Zack</h1>
                <h4 className="fade-in-bottom">Official Author Website</h4>
            </div>
                <h3>Photo by Rowland Scherman, National Archives</h3>
        </section>
    )
}

export default Front;