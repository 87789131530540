import React from "react";
import quote from "../images/quote.png";

const InPress = () => {
    let quoteImage = <img alt="quotes" src={quote} />;
    let quotes = [
        "A well-researched, engaging biography of a pioneering and influential figure in American music.",
        "A solid work of reportage and writing. ...There are many excellent details in Zack's biography.",
        `Odetta brims with the life of its forgotten subject, showing us that we have a lot of cultural history to re-learn, and many losses yet to mourn.`,
        "Odetta deserved better during her lifetime, but this biography moves toward properly cementing her legacy.",
        "Ian Zack's inspiring biography [reminds] readers of her place in the pantheon.",
        "Fascinating . . . . Odetta fans will delight in this timely biography."
    ];
    let line2 = [
        "EDDIE DEAN",
        "DWIGHT GARNER",
        "JOSEPHINE LIVINGSTONE",
        "AARON COHEN",
        "MICHAEL SIMMONS",
        "PUBLISHER'S WEEKLY",
    ]
    let line3 = [
        "THE WALL STREET JOURNAL",
        "THE NEW YORK TIMES",
        "THE NEW REPUBLIC",
        "DOWNBEAT",
        "MOJO MAGAZINE",
        "(STARRED REVIEW)"
    ]
    return (
        <section id="inPress">
            <h1>In the Press</h1>
            <hr />
            <div className="slide-in-bottom reveal table">
                {quotes.map((quote, i) => (
                    <div className="item" key={i}>
                        {quoteImage}
                        <p className="light quote">{quote}</p>
                        <p className="line2">{line2[i]}</p>
                        <p className="line3 light">{line3[i]}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default InPress;