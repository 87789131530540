import React from "react";

const News = () => {
    return (
        <section id="news">
            <h1>News</h1>
            <span className="reveal slide-in-left newslinktext">
                <p><u>March 21, 2024</u></p>
                <p>Read Ian Zack's <a href="https://www.nytimes.com/2024/03/21/arts/julie-belafonte-dead.html" target="_blank">article</a> on the life of Julie Robinson Belafonte in <i>The New York Times</i>.</p>
                <br />
                <p><u>May 26, 2023</u></p>
                <p>The Italian edition of <i>Say No to the Devil</i> is <a target="_blank"
                    href="https://www.amazon.it/reverendo-Davis-grande-leggenda-blues/dp/B0BGVCZ3CS/ref=sr_1_1?__mk_it_IT=ÅMÅŽÕÑ&crid=GPOF7GWV5BNP&keywords=ian+zack&qid=1685900400&sprefix=ian+zack%2Caps%2C177&sr=8-1">released</a>.</p>
                <br />
                <p><u>February 7, 2023</u></p>
                <p>Read Ian Zack's <a target="_blank"
                    href="https://www.nytimes.com/2023/02/07/obituaries/frances-ellen-watkins-harper-overlooked.html">article</a> about poet and abolitionist Frances Ellen Watkins Harper in the <i>New York Times</i>' "Overlooked No More"
                    series.</p>
                <br />
                <p><u>March 24, 2021</u></p>
                <p>Odetta&apos;s 1956 album, Odetta Sings Ballads and Blues, named to the National Recordin. Read
                    Ian Zack&apos;s essay <a
                        href="https://www.loc.gov/static/programs/national-recording-preservation-board/documents/Odetta_Zack.pdf">here</a> for the Library of Congress commemorating the record.</p>
                <br />
                <p><u>December 2, 2020 </u></p>
                <p>New York Times book critics <a target="_blank"
                    href="https://www.nytimes.com/2020/12/02/books/critics-roundtable-year-in-books.html">discuss</a> new
                    discoveries and best books of 2020.</p>
                <br />
                <p><u>August 24, 2020 </u></p>
                <p>Odetta: A Life in Music and Protest <a target="_blank"
                    href="https://www.nytimes.com/2020/08/24/books/review-odetta-biography-ian-zack-one-grain-of-sand-matthew-frye-jacobson.html">reviewed</a> by the New York Times.</p>
                <br />
                <p><u>April 14, 2020 </u></p>
                <p>Odetta: A Life in Music and Protest <a target="_blank"
                    href="http://www.beacon.org/Odetta-P1563.aspx">released</a> by Beacon Press.</p>
                <br />
                <p><u>November 21, 2016</u></p>
                <p>The Association for Recorded Sound Collections <a target="_blank"
                    href="https://www.arsc-audio.org/pdf/press-release/pr_2016_awardsexcellence.pdf">names</a> Say No the
                    Devil: The Life and Musical Genius of Rev. Gary Davis the year&apos;s best blues or gospel history.</p>
                <br />
                <p><u>April 10, 2015</u></p>
                <p>Say No the Devil: The Life and Musical Genius of Rev. Gary Davis is <a target="_blank"
                    href="https://press.uchicago.edu/ucp/books/book/chicago/S/bo19609757.html">released</a> by the
                    University of Chicago Press.</p>
            </span>
        </section>
    )
}

export default News;