import { addToAnimatedComponents } from "./animationsession";

function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 20;
  
      if (elementTop < windowHeight - elementVisible) {
        var section = getSection(reveals[i]);
        addToAnimatedComponents(section.id);
        reveals[i].classList.add("active");
      } 
  
    }
  }

function getSection(element) {
  if (element.tagName === "SECTION") {
    return element;
  }
  else {
    return getSection(element.parentElement);
  }
}
  

export default reveal;