import React from "react";
import gary from "../images/book2.jpg";
import Navbar from "./navbar";

const SayNoToTheDevil = () => {
    return (
        <div>
            <Navbar />
            <section id="say-no-to-the-devil">
            <div className="container">
                <div className="left">
                    <img src={gary} alt="Say No to the Devil" />
                    <div className="buttons">
                        <a target="_blank" href="https://www.indiebound.org/book/9780226234106">Indie Bound</a>
            
                        <a target="_blank" href="https://www.amazon.com/gp/product/B00SLVOMMW/ref=dbs_a_def_rwt_bibl_vppi_i1">Amazon</a>
                    </div>
                    <p>
                        <i>"[A] magisterial biography. . . . Half saint, half sinner, all singer-songwriter, . . . the Rev. Gary Davis is that blind man you brushed by on your way to the stadium to see the big act perform. Zack's brilliant account compels us to pause for a long look at an artist who was always there, even if we didn't see him."</i>
                    </p>
                    <p>
                    —David Kirby, Wall Street Journal
                    </p>
                </div>
                <div className="right active reveal slide-in-right">
                    <h1>Say No to the Devil</h1>
                    <p>
                        Who was the greatest of all American guitarists? You probably didn't name Gary Davis, but many of his musical contemporaries considered him without peer. Bob Dylan called Davis "one of the wizards of modern music. " Bob Weir of the Grateful Dead—who took lessons with Davis–claimed his musical ability "transcended any common notion of a bluesman." And the folklorist Alan Lomax rated him "one of the really great geniuses of American instrumental music, a man who belongs in the company of Louis Armstrong and Sidney Bechet." But you won't find Davis alongside blues legends Robert Johnson and Muddy Waters in the Rock and Roll Hall of Fame, even though his songs have been covered by Dylan, Jackson Browne, and many other hall of famers.
                    </p>
                    <p>
                        The first biography of Davis, Say No to the Devil restores "the Rev's" remarkable story. Drawing on extensive research and more than 125 interviews, Zack takes readers through Davis's difficult beginning as the blind son of sharecroppers in the Jim Crow South to his decision to become an ordained Baptist minister and his move to New York in the early 1940s, where he scraped out a living singing and preaching on street corners and in storefront churches in Harlem. There, he gained entry into a circle of musicians that included Lead Belly, Woody Guthrie, and Dave Van Ronk. But in spite of his tremendous musical achievements, Davis never gained broad recognition from an American public that wasn't sure what to make of his trademark blend of gospel, ragtime, street preaching, and the blues. His personal life was also fraught, troubled by struggles with alcohol, women, and deteriorating health.
                    </p>
                    <p>
                        Zack chronicles this remarkable figure in American music, helping us to understand how he taught and influenced a generation of musicians.
                    </p>
                </div>
            </div>
        </section>
        </div>
        
    )
}

export default SayNoToTheDevil;