import React from "react";
import odetta from "../images/book1.jpg";
import Navbar from "./navbar";

const Odetta = () => {
    return (
        <div>
            <Navbar />
            <section id="odetta">
            <div className="container">
                <div className="left">
                    <img src={odetta} alt="Odetta" />
                    <div className="buttons">
                        <a target="_blank" href="https://www.indiebound.org/book/9780807035320?aff=PublishersWeekly">Indie Bound</a>
                        <a target="_blank" href="https://www.amazon.com/Odetta-Music-Protest-Ian-Zack/dp/0807035327">Amazon</a>
                    </div>
                </div>
                <div className="right active slide-in-right">
                    <h1>Odetta</h1>
                    <p><i>Based on interviews with Harry Belafonte, Joan Baez, Judy Collins, Carly Simon, and many other musical colleagues and close friends.</i></p>
        
                    <p>Odetta channeled her anger and despair into some of the most powerful folk music the world has ever heard. Through her lyrics and iconic persona, Odetta made lasting political, social, and cultural change.</p>
            
                    <p>A leader of the 1960s folk revival, Odetta influenced a huge number of artists over many decades, including Bob Dylan, Janis Joplin, the Kinks, Jewel, and, more recently, Rhiannon Giddens and Miley Cyrus.</p>
            
                    <p>But Odetta's importance extends far beyond music. Journalist Ian Zack follows Odetta from her beginnings in deeply segregated Birmingham, Alabama, to stardom in San Francisco and New York. Odetta used her fame to bring attention to the civil rights movement, working alongside Joan Baez, Harry Belafonte, and other artists. Her opera-trained voice echoed at the 1963 March on Washington and the Selma to Montgomery march, and her "Freedom Trilogy" songs became rallying cries for protesters everywhere.</p>
            
                    <p>Zack brings Odetta back into the spotlight, reminding the world of the folk music that powered the civil rights movement and continues to influence generations of musicians today.</p>
            
                </div>
            </div>
        </section>
        </div>
        
    )
}

export default Odetta;