import React from "react";
import odetta from "../images/book1.jpg";
import gary from "../images/book2.jpg";

const Books = () => {
    return (
        <section id="books">
            <div className="header">
                <h1>Books</h1>
                <h4>Click on a title for more information</h4>
            </div>
            <div className="divider-container"><hr className="top-divider" /></div>
            
            <div className="books">
                <div className="odetta" onClick={
                    () => window.location.href = "/books/odetta"
                }>
                    <img src={odetta} className="reveal slide-in-left" alt="Odetta" />
                    <hr className="reveal slide-in-bottom book-divider" />
                    <p className="reveal slide-in-bottom text-focus-in">Odetta</p>
                </div>
                <div className="gary-davis" onClick={
                    () => window.location.href = "/books/say-no-to-the-devil"
                
                }>
                    <img className="reveal slide-in-right" src={gary} alt="Rev Gary Davis" />
                    <hr className="reveal book-divider slide-in-bottom" />
                    <p className="reveal slide-in-bottom text-focus-in">Say No to the Devil</p>
                </div>
            </div>
            <a href="https://www.nytimes.com/2024/03/21/arts/julie-belafonte-dead.html" target="_blank" className="reveal slide-in-bottom button">
            Read Ian Zack's article on the life of Julie Robinson Belafonte in <i>The New York Times</i>.
            </a>
        </section>
    )
}

export default Books;