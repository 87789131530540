import { parseCookies, setCookie } from "nookies";


function addToAnimatedComponents(component) {
    component = String(component);
    setCookie(null, component, true, {
        // 20 mins
        maxAge: 1200,
        path: '/',
    })
}

function getAnimatedComponents() {
    const cookies = parseCookies();
    return Object.keys(cookies);
}

function setComponentsToNotAnimate(container) {
    // get elements with class name reveal that are children of the container
    const elementsInElementWithClass = container.getElementsByClassName("reveal");
    elementsInElementWithClass.array.forEach(element => {
        element.classList.remove("reveal");
    });
}

export { addToAnimatedComponents, getAnimatedComponents, setComponentsToNotAnimate };