import React, { useEffect, useState } from "react";

const ContactForm = () => {
    const [number, setNumber] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    useEffect(() => {
        let randomInt = getRandomInt(320);
        setNumber(randomInt);
        let robotElement = document.getElementById("robot");
        let numberElement = document.getElementById("number");
        if(robotElement && numberElement) {
            robotElement.placeholder = randomInt;
            numberElement.textContent = randomInt;
        }
    }, []);

    function onSubmit() {
        let robotElement = document.getElementById("robot");
        if (robotElement && robotElement.value == number) {
            document.getElementById("contactMe").action = "https://docs.google.com/forms/d/e/1FAIpQLSfPYgv-VIdXQMmoiSaoBm6aPXfCjrpYlC0O7tiqwAWcv7XE1A/formResponse"
            setSubmitted(true);
        }
        else {
            alert("Please type the number displayed in the last text box")
            return
        }
    }
    return (
        <section className="slide-in-left reveal" id="contact">
            <h1>Contact</h1>
            <iframe name="hidden" id="hidden" style={{display: "none"}}
                onLoad={submitted ? window.location.href = "/submitted" : null}></iframe>
            <form method="post" onSubmit={onSubmit}
                id="contactMe" target="hidden">
                <input type="text" name="entry.1446850997" id="name" placeholder="Name" required />
                <input type="tel" name="entry.235286712" id="tel" placeholder="Phone Number" />
                <input type="email" name="entry.1923515753" id="email" placeholder="Email" required />
                <textarea placeholder="Message" form="contactMe" name="entry.1911005577" id="message"></textarea>
                <h4>To verify you are not a robot, please type "<span id="number"></span>" in the text field below.</h4>
                <input type="text" id="robot" />
                <button type="submit">Send</button>
            </form>
        </section>
    )
}

export default ContactForm;