import React from "react";

const Navbar = () => {
    function mobileNavTogller() {
        var x = document.getElementById("myTopnav");
        if (x.className === "topnav") {
            x.className += " responsive";
        } else {
            x.className = "topnav";
        }
    }
    return (
        <div>
            <header className="reg-nav">
                <ul>
                    <li><a href="/#home">Home</a></li>
                    <li><a href="/#books">Books</a></li>
                    <li><a href="/#inPress">In The Press</a></li>
                    <li><a href="/#bio">Bio</a></li>
                    <li><a href="/#news">News</a></li>
                    <li><a href="/#contact">Contact</a></li>
                </ul>
            </header>
{/* 
            <header className="mobile-nav">
               
            </header> */}
        </div>
    )
}

export default Navbar;