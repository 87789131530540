import React, { useEffect } from "react";
import ian from "../images/ianzack.webp";

const Bio = () => {
    return (
        <section id="bio">
            <h1>Bio</h1>
            <hr />
                <div className="reveal slide-in-bottom container">
                    <div>
                        <img alt="Picture of Ian Zack" src={ian} />
                    </div>
                    <div>
                        <h5 className="biotext">Ian Zack has been a writer and editor for two decades.
                            His work has appeared in the <i>New York Times</i>, the <i>Washington Post</i>, and many other publications.
                            Zack&apos;s award-winning first book, <i>Say No to the Devil: The Life and Musical Genius of Rev.
                                Gary Davis</i>, was called <i>"magisterial"</i> by the <i>Wall Street Journal</i>. He lives in
                            New York City.
                        </h5>
                    </div>
                </div>
            <h4 className="reveal slide-in-bottom">Photo by Sam Zack</h4>
        </section>
    )
}

export default Bio;